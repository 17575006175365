<template>
  <div class="home">
    <el-menu class="el-menu-demo nav-brand hidden-md-and-down" mode="horizontal" @select="handleSelect">
      <template v-for="(item, index) in asidList">
        <el-menu-item :index="item.brand_id + ''" :key="index" v-if="index < 8">{{ item.brand_name }}</el-menu-item>
      </template>

      <el-menu-item index="999"><el-button>more...</el-button></el-menu-item>
    </el-menu>
    <el-carousel :interval="4000" height="35vw">
      <el-carousel-item v-for="(item, index) in banner_list" :key="index">
        <img style="width: 100%; height: auto" :src="item.banner_url" ref="imgH" />
      </el-carousel-item>
    </el-carousel>
    <h1>THE LATEST ARRIVALS</h1>
    <div class="goods-list clear">
      <template v-for="(item, index) in asidList">
        <div @click="handleSelect(item.brand_id, item.classify)" :key="index" v-if="index < 9" class="pro-card-div">
          <el-card shadow="never">
            <el-image style="width: 100%" :src="item.brand_logo" fit="contain"></el-image>
            <div class="hoverDiv"></div>
            <div class="hoverDiv info">
              <h5>{{ item.brand_name }}</h5>
            </div>
          </el-card>
        </div>
      </template>

      <div @click="handleSelect(999)" class="pro-card-div">
        <el-card shadow="never">
          <el-image style="width: 100%" :src="require('../assets/more.jpg')" fit="contain"></el-image>
        </el-card>
      </div>
    </div>
    <h1 style="
                                        background-color: #000;
                                        color: #fff;
                                        display: block;
                                        margin: 20px auto;
                                        width: max-content;
                                      ">
      HOT SELLERS
    </h1>
    <div class="advance clear">
      <div class="ad-block" v-for="(item, index) in shop_list" :key="index">
        <div class="flex" @click="detail(item.id)">
          <div style="width: 50%">
            <el-image style="width: 100%" :src="item.cover_url" fit="cover"></el-image>
          </div>
          <div style="width: 50%; padding-top: 38px; box-sizing: border-box">
            <h3>
              <em><strong>{{ item.name }}</strong></em>
            </h3>
            <div>
              <el-button class="shopNow" @click="detail(item.id)">shop now</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h1 style="
                                        background-color: #000;
                                        color: #fff;
                                        display: block;
                                        margin: 20px auto;
                                        width: max-content;
                                      ">
      LATEST STYLE
    </h1>
    <div class="advance clear">
      <div class="ad-block" v-for="(item, index) in hot_list" :key="index">
        <div class="flex" @click="detail(item.id)">
          <div style="width: 50%">
            <el-image style="width: 100%" :src="item.cover_url" fit="cover"></el-image>
          </div>
          <div style="width: 50%; padding-top: 38px; box-sizing: border-box">
            <h3>
              <em><strong>{{ item.name }}</strong></em>
            </h3>
            <div>
              <el-button class="shopNow" @click="detail(item.id)">shop now</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="goods-list clear hidden">
      <div @click="detail(item.id)" v-for="(item, index) in recommend_list" :key="index" class="pro-card-div">
        <el-card shadow="never">
          <el-image
          style="width: 100px; height: 100px"
          src="../assets/shoose.jpg"
        ></el-image>
          <el-image style="width: 100%" :src="item.cover_url" fit="cover"></el-image>
          <div class="hoverDiv"></div>
          <div class="hoverDiv info">
            <h5>{{ item.classify_name }}</h5>
            <p>{{ item.price }}$</p>
          </div>
        </el-card>
      </div>
    </div> -->
  </div>
</template>

<script>
// @ is an alias to /src
import { getIndex, getClassify, getHotg, getNewsg } from '@/api/index'
import { Decrypt } from '@/utils/crypto'
export default {
  name: 'home',
  data() {
    return {
      list: '',
      banner_list: [],
      hot_list: [],
      shop_list: [],
      asidList: [],
      recommend_list: [],
    }
  },
  created() {
    getClassify().then((response) => {
      let jie = Decrypt(response)
      let data = JSON.parse(jie)
      this.asidList = data.data
    })
    this.getList()
  },
  methods: {
    handleSelect(id, classify_id) {
      this.$store.commit('setClassify_id', classify_id)
      if (id == 999) {
        this.$router.push({ path: './brand' })
      } else {
        // this.$router.push({ path: './product', query: { brand_id: id, classify_id: classify_id[0].classify_id } })
        this.$router.push({ path: './product', query: { brand_id: id, classify_id: classify_id[0].classify_id } })
      }
    },
    getList() {
      getIndex().then((response) => {
        let jie = Decrypt(response)
        let data = JSON.parse(jie)

        // this.hot_list = data.data.hot_list
        this.banner_list = data.data.banner_list
        // this.shop_list = data.data.shop_list
        this.recommend_list = data.data.recommend_list
      })
      getHotg().then((response) => {
        let jie = Decrypt(response)
        let data = JSON.parse(jie)

        this.shop_list = data.data

      })
      getNewsg().then((response) => {
        let jie = Decrypt(response)
        let data = JSON.parse(jie)

        this.hot_list = data.data

      })
    },
    detail(id) {
      this.$router.push({ path: './detail', query: { id } })
    },
  },
}
</script>
