const CryptoJS = require('crypto-js') //引用AES源码js
// let Base64 = require('js-base64').Base64

const key = CryptoJS.enc.Utf8.parse('wp2022110611111!') //十六位十六进制数作为密钥

//解密方法
function Decrypt(word) {
  // let code = Base64.decode(word)
  var result = CryptoJS.AES.decrypt(word, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  })
  return result.toString(CryptoJS.enc.Utf8)
}

//加密方法
function Encrypt(word) {
  let encryptedData = CryptoJS.AES.encrypt(word, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  })
  return encryptedData.toString()
}

export { Decrypt, Encrypt }
