import request from '@/utils/request'
export function getIndex(data) {
  return request({
    url: '/api/index/index',
    method: 'get',
    params: data,
  })
}
export function getClassify(data) {
  return request({
    url: '/api/index/brandClassify',
    method: 'get',
    params: data,
  })
}
export function getGoods(data) {
  return request({
    url: '/api/index/classifyList',
    method: 'get',
    params: data,
  })
}
export function getDetail(data) {
  return request({
    url: '/api/index/detail',
    method: 'get',
    params: data,
  })
}
export function getLink(data) {
  return request({
    url: 'api/index/link',
    method: 'get',
    params: data,
  })
}
export function getHotg(data) {
  return request({
    url: '/api/index/hotg',
    method: 'get',
    params: data,
  })
}
export function getNewsg(data) {
  return request({
    url: '/api/index/newsg',
    method: 'get',
    params: data,
  })
}