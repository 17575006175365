import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      paths: ['include']
    })
  ],

  state: {
    include: [],
    classify_idForPhoneSelect:''
  },

  mutations: {
    setInclude(state, include) {
      state.include = include.filter((item) => item)
      console.log(JSON.stringify(state.include))
    },
    setClassify_id(state, classify_id) {
      state.classify_idForPhoneSelect = classify_id
      console.log(classify_id)
    },
  }
})