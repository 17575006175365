<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <el-container>
      <el-header style="height: 100px">
        <nav style="position: relative">
          <div class="nav-tag hidden-sm-and-up" @click="toggleNav">
            <i class="el-icon-menu"></i>
          </div>
          <div class="logo-box">
            <img src="./assets/logo.jpg" alt="" style="width: 1000px" @click="gohome" />
          </div>
        </nav>
      </el-header>
      <el-main> <keep-alive :include="include"><router-view /></keep-alive></el-main>
      <el-footer>
        <div class="clear">
          <div style="float: left">
            <span>Copyright © 2023 WANLOAL</span>
          </div>
          <div style="float: right">
            <img src="https://www.wanlola.com/qfy-content/uploads/2021/04/5d9cd39ed145dc1fb160aa4335349cd0-100.webp"
              alt="" />
          </div>
        </div>
      </el-footer>
      <el-drawer title="Please select..." :visible.sync="drawer" direction="ltr" :before-close="handleClose" size="60%">
        <el-aside width="100%;" class="">
          <el-menu @select="selectSeri" :default-active="activeIndex">
            <el-submenu :index="item.brand_id + ''" v-for="(item, index) in asidList" :key="index">
              <template slot="title">
                <el-image style="width: 3rem;" :src="item.brand_logo" fit="contain"></el-image>
                {{ item.brand_name }}
              </template>
              <el-menu-item :index="p.classify_id + ''" v-for="(p, o) in item.classify" :key="o"
                @click="handleSetClassify_id(p.classify_id)">{{ p.classify_name
                }}</el-menu-item>
            </el-submenu>
          </el-menu>
        </el-aside>
      </el-drawer>
    </el-container>
    <div class="concat-box">
      <a :href="link.whatsapp" class="hidden-md-and-down" v-if="link.whatsapp"><img src="./assets/what.png" alt=""
          style="width: 100%" /></a>
      <a :href="link.whatsapp" class="hidden-sm-and-up" v-if="link.whatsapp"><img src="./assets/what.png" alt=""
          style="width: 100%" /></a>
      <a :href="link.instag" v-if="link.instag"><img src="./assets/ins.webp" alt="" style="width: 100%" /></a>
      <a :href="link.facebook" v-if="link.facebook"><img src="./assets/face.png" alt="" style="width: 100%" /></a>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import { getClassify, getLink } from '@/api/index'
import { Decrypt } from '@/utils/crypto'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      activeIndex: '',
      searchValue: '',
      drawer: false,
      asidList: [],
      activeBrand: '',
      link: {
        facebook: '0',
        instag: '0',
        whatsapp: '0'
      }
    }
  },
  computed: {
    ...mapState(['include', 'classify_idForPhoneSelect'])
    // ...mapState(['include', 'bb'])
  },
  created() {

    getClassify().then((response) => {
      let jie = Decrypt(response)
      let data = JSON.parse(jie)
      this.asidList = data.data
    })
    getLink().then((response) => {
      let jie = Decrypt(response)
      let data = JSON.parse(jie)
      this.link.facebook = data.data.facebook
      this.link.instag = data.data.instag
      this.link.whatsapp = data.data.whatsapp
    })
  },
  watch: {
    classify_idForPhoneSelect: {
      handler(val) {
        console.log('这个监听val,', val)
        Array.isArray(val) ? this.activeIndex = val[0].classify_id + '' : this.activeIndex = val + ''
      }


    }
  },
  methods: {
    handleSetClassify_id(item) {
      this.$store.commit('setClassify_id', item)
    },
    handleClose() {
      this.drawer = false
    },
    selectSeri(id) {
      this.drawer = false
      for (var i = 0; i < this.asidList.length; i++) {
        for (var j = 0; j < this.asidList[i].classify.length; j++) {
          if (this.asidList[i].classify[j].classify_id == id) {
            this.activeBrand = this.asidList[i].classify
            break
          }
        }
      }
      this.$router.push({ path: './product', query: { classify_id: id, brand_id: this.activeBrand[0].brand_id } })
    },
    toggleNav() {
      this.drawer = true
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath)
    },
    search() {
      this.$router.redirect('./product')
    },
    gohome() {
      this.$router.push('/')
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.el-submenu__title {
  display: flex;
  align-items: center;
}

.el-submenu__title>.el-image {
  margin-top: 0;
  display: flex;
  align-items: center;
  margin-right: 6px;
}
</style>
