import axios from 'axios'
import { Loading } from 'element-ui'
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000, // request timeout
})
let loadingInstance = null
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    loadingInstance = Loading.service({ fullscreen: true })
    return config
  },
  (error) => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    loadingInstance.close()
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    // if (res.code !== 1) {
    //   // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    //   if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
    //     console.log(2)
    //   }
    //   return Promise.reject(new Error(res.msg || 'Error'))
    // } else {
    //   return res
    // }
    return res
  },
  (error) => {
    console.log('err' + error) // for debug

    return Promise.reject(error)
  }
)

export default service
